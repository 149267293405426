<i18n>
ru:
  averageTime: 'Время приготовления составит {min} минут'
  averageTimeDelivery: 'Время доставки составит {min} минут'
  changedMinSubByRule: 'Минимальная сумма заказа сейчас {price}'
  dataConsent: Я даю согласие на
  dataConsentLink: обработку своих персональных данных.
  errorClientInBlacklist: Вы находитесь в чёрном списке ресторана и не можете оформить заказ
  errorDeliveryCity: Выберите город.
  errorDeliveryDepartment: Выберите отдел.
  errorDeliveryDistrict: Выберите район.
  errorDeliveryHouse: Укажите дом.
  errorDeliveryStreet: Укажите улицу.
  errorDeliveryTerminal: Выберите терминал.
  errorEmpty: Корзина пуста. Добавьте в неё что-нибудь.
  errorForbidden: Некоторые товары в корзине недоступны для заказа. Проверьте корзину.
  errorInHall: Не выбран стол или ресторан для заказа в зале.
  errorInternalSettings: 'Ошибка при расчёте стоимости доставки. Некорректно настроены
    правила работы с платной доставкой, свяжитесь со специалистами'
  errorInvalid: 'В корзине присутствуют продукты, не прошедшие валидацию.'
  errorMinSum: 'Извините, но минимальная стоимость заказа - {minsum} руб. Просто закажите что-нибудь ещё.'
  errorMinSumGeneral: Не достигнута минимальная стоимость заказа. Просто закажите что-нибудь ещё.
  errorOrderDestinationIsNotValid: Не заполнены все поля для доставки/самовывоза.
  errorOrderType: Выберите способ получения заказа.
  errorPay: Не выбран тип оплаты!
  errorPersonalInfo: Укажите имя и номер телефона.
  errorPromoCodeIsInvalid: Промокод просрочен!
  errorRecipient: Не выбран способ получения чека
  errorSelfService: Не выбран ресторан для самовывоза.
  errorUnknown: Неизвестная ошибка!
  minDeliverySum: 'Минимальная стоимость вашего заказа:'
  notBlockingNo: Назад
  notBlockingYes: Продолжить оформление
  personalDataMessage: 'Оформляя заказ, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  secondStep: К оформлению заказа
  submit: Оформить заказ
ua:
  averageTime: 'час приготування складе {min} хвилин'
  averageTimeDelivery: 'час доставки складе {min} хвилин'
  changedMinSubByRule: 'Мінімальна сума замовлення зараз {price}'
  dataConsent: Я даю згоду на
  dataConsentLink: обробку персональних даних.
  errorClientInBlacklist: Ви перебуваєте в чорному списку ресторану і не можете оформити замовлення
  errorDeliveryCity: Оберіть місто.
  errorDeliveryDepartment: Оберіть відділ.
  errorDeliveryDistrict: Оберіть район.
  errorDeliveryHouse: Вкажіть будинок.
  errorDeliveryStreet: Вкажіть вулицю.
  errorDeliveryTerminal: Оберіть термінал.
  errorEmpty: Кошик пустує. Додайте до нього що-небудь.
  errorForbidden: У кошику присутні товари неможливі для замовлення вибраним способом.
  errorInHall: Не обраний стіл або ресторан для замовлення в залі.
  errorInternalSettings: Помилка при розрахунку вартості доставки. Некоректно налаштовані правила роботи з платною доставкою. Будь ласка зв’яжіться з фахівцями.
  errorInvalid: 'У кошику присутні товари, які не пройшли валідацію.'
  errorMinSum: 'Пробачте, но мінімальна вартість замовлення — {minsum} грн. Замовте
    щось додатково.'
  errorMinSumGeneral: 'Пробачте, но не досягнута мінімальна сума замовлення. Замовте
    щось додатково.'
  errorOrderDestinationIsNotValid: Не заповнені всі поля для доставки / самовивозу.
  errorOrderType: Оберіть спосіб отримання замовлення.
  errorPay: Не вибрано тип оплати
  errorPersonalInfo: Вкажіть ім’я та номер телефону.
  errorPromoCodeIsInvalid: Промокод прострочений!
  errorRecipient: Не обрано спосіб отримання чеку
  errorSelfService: Не вибрано ресторан для самовивозу.
  errorUnknown: Невідома помилка!
  minDeliverySum: 'Сума замовлення:'
  notBlockingNo: Назад
  notBlockingYes: Продовжити оформлення
  personalDataMessage: 'Оформляючи замовлення, Ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  secondStep: До оформлення замовлення
  submit: Оформити замовлення
us:
  averageTime: 'Cooking time will be {min} minutes'
  averageTimeDelivery: 'Delivery time will be {min} minutes'
  changedMinSubByRule: 'The minimum order amount is now {price}'
  dataConsent: I agree to
  dataConsentLink: the terms of use.
  errorClientInBlacklist: You are on the restaurant blacklist and cannot place an order
  errorDeliveryCity: Select delivery city.
  errorDeliveryDepartment: Select delivery department.
  errorDeliveryDistrict: Select delivery area.
  errorDeliveryHouse: Specify your house.
  errorDeliveryStreet: Specify your street.
  errorDeliveryTerminal: Select delivery terminal.
  errorEmpty: Your cart is empty. Add something to it.
  errorForbidden: Some items in your cart are unavailable for purchase. Check your cart.
  errorInHall: No table or restaurant has been selected for ordering in hall.
  errorInternalSettings: Error while calculating delivery price. Incorrect delivery settings. Please contact support
  errorInvalid: Cart have items that were not validated.
  errorMinSum: 'Sorry, but minimum order amount is {minsum}. Just order something else.'
  errorMinSumGeneral: 'Sorry, but minimum order amount has not been reached. Just order
    something else.'
  errorOrderDestinationIsNotValid: Not all fields required for delivery/pickup are filled in.
  errorOrderType: Select a way to receive your order.
  errorPay: Payment type not chosen
  errorPersonalInfo: Enter your name and phone number.
  errorPromoCodeIsInvalid: The promo code is expired!
  errorRecipient: Receipt method not selected
  errorSelfService: You haven’t selected a restaurant for self-service.
  errorUnknown: Unknown error!
  minDeliverySum: 'Minimal order cost:'
  notBlockingNo: Cancel
  notBlockingYes: Proceed with your order
  personalDataMessage: 'By placing an order, you agree to '
  personalDataMessageLink: the terms of personal data processing
  secondStep: Proceed to order
  submit: Submit order
</i18n>

<template>
  <div
    v-if="
      appConfig.VueSettingsPreRun.EnablePersonalDataWarning &&
      !appConfig.VueSettingsPreRun.PersonalInfoAskForConsent
    "
    class="v-cart-data-warning"
  >
    <span
      class="v-mr-xxs"
      v-html="translate('secondStepButton.personalDataMessage')"
    />
    <arora-nuxt-link
      open-in-new-tab
      :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
      :label="translate('secondStepButton.personalDataMessageLink')"
    />
  </div>
  <div
    v-if="appConfig.VueSettingsPreRun.PersonalInfoAskForConsent"
    class="v-cart-data-warning v-d-flex v-flex-row v-mb-sm"
  >
    <input
      id="v-check_privacy"
      class="v-input-checkbox v-label-check-privacy"
      type="checkbox"
      v-model="dataConsent"
    />
    <label
      class="v-label-check-privacy"
      for="v-check_privacy"
    >
      <span class="v-checkbox" />
      <div class="v-checkbox-text">
        <span
          class="v-mr-xxs"
          v-html="translate('secondStepButton.dataConsent')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('secondStepButton.dataConsentLink')"
        />
      </div>
    </label>
  </div>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="minSumNotReached"
      class="v-min-delivery-sum v-mb-sm"
      data-test-id="min-delivery-sum-text"
    >
      <span v-html="translate('secondStepButton.minDeliverySum')" />
      <common-currency
        :amount="minSumForDelivery ?? 0"
        data-test-id="min-delivery-sum"
      />
    </div>
  </transition>

  <common-skeleton v-if="orderButtonBlocked" />
  <arora-button
    v-else
    class-name="v-btn-lg v-submit-button"
    :disabled="buttonDisabled"
    :label="translate('secondStepButton.submit')"
    data-test-id="submit-order-button"
    @click="async () => await showMessagesAndSendOrder()"
  />
</template>

<script setup lang="ts">
import type { CartItem, MessagesV2 } from '~types/clientStore'
import type { popupChangedProductsContent } from '~types/popupStore'

import { type GUID, useCommon, useValidationStore } from '@arora/common'

import { getActivePinia } from 'pinia'
import { Guid, MessageType, OrderSendErrors, OrderType, PayType, ReceiptSendType } from '~api/consts'

const { translate } = useI18nSanitized()

const restaurantStore = useRestaurantStore()
const addressStore = useAddressStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const { districtDeliveryParametersByTerminal, minSumNotReached, terminal, terminalTemporarilyNoActive } =
  useAddressSelector()
const { stringIsNullOrWhitespace } = useCommon()
const { pathname } = useUrl()
const { deleteItemsArray } = useCartItems()
const appConfig = useAppConfig()

const orderButtonBlocked = ref<boolean>(false)
const dataConsent = ref<boolean>(false)

const buttonDisabled = computed<boolean>(() => {
  if (!clientStore.ClientState?.data || !terminal.value) return true

  //consent check
  if (appConfig.VueSettingsPreRun.PersonalInfoAskForConsent && !dataConsent.value) return true

  if (minSumNotReached.value) return true

  if (
    appConfig.RestaurantSettingsPreRun.OneStageCart ||
    pathname === appConfig.VueSettingsPreRun.Links.CartSecondStep
  ) {
    const restrictions = clientStore.TimeRestrictions?.data

    if (restrictions) {
      if (clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? false) {
        if (
          !(restrictions.ASAP?.EnabledByTerminalSettings ?? true) ||
          !restrictions.ASAP?.AvailableNow
        ) {
          return true
        }
      } else if (!(restrictions.Scheduled?.EnabledByTerminalSettings ?? true)) {
        return true
      }
    }

    if (appConfig.RestaurantSettingsPreRun.AllowDisableCallback) {
      //callback check
      return (
        clientStore.ClientState.data?.UserOrderedCallback !== true &&
        clientStore.ClientState.data?.UserOrderedCallback !== false
      )
    }
  }

  //just in case - if client state null (somehow)
  return !clientStore.ClientState?.data || orderButtonBlocked.value
})

const payType = computed<PayType>(() => {
  return clientStore.ClientState?.data?.StateOrderData?.PaymentType ?? PayType.Undefined
})

const receiptSendType = computed<ReceiptSendType>(() => {
  return clientStore.ClientState?.data?.StateOrderData?.ReceiptSendType ?? ReceiptSendType.Default
})

const minSumForDelivery = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0
)
const validatorForm = 'cart'

onMounted(async () => {
  if (import.meta.client) await addressStore.initCities()

  //handle redirect from server
  if (window.location.hash !== '') {
    switch (window.location.hash) {
      case '#cart-noitems':
        await popupStore.showError(
          translate('secondStepButton.errorEmpty'),
          'empty-invalid-or-forbidden'
        )
        break
      case '#forbidden-to-order':
      case '#cart-forbidden':
        await popupStore.showError(
          translate('secondStepButton.errorForbidden'),
          'empty-invalid-or-forbidden'
        )
        break
      case '#cart-ivalid':
      case '#data-invalid':
        await popupStore.showError(
          translate('secondStepButton.errorInvalid'),
          'empty-invalid-or-forbidden'
        )
        break
      case '#city-notfound':
        await popupStore.showError(translate('secondStepButton.errorDeliveryCity'))
        break
      case '#district-notfound':
        await popupStore.showError(translate('secondStepButton.errorDeliveryDistrict'))
        break
      case '#cart-minsumnotreached':
        await popupStore.showError(
          translate('secondStepButton.errorMinSumGeneral'),
          'min-sum-not-reached'
        )
        break
    }

    //this will remove hash, so that error message will not be repeated
    window.history.replaceState(null, '', pathname)
  }
})

async function showMessagesAndSendOrder(): Promise<void> {
  await restaurantStore.Metrics?.sendMetricBeforeOrder()

  const needToShowProductsWithPriceChanges = await clientStore.needToShowProductsWithPriceChanges()
  const unavailableProducts = await clientStore.getUnavailableProducts()

  const popupChangedProductsContent: popupChangedProductsContent[] = []

  if (!needToShowProductsWithPriceChanges) return
  if (clientStore.productsWithPriceChanges.length > 0) {
    const productsWithPriceChangesIDs: Set<GUID> = new Set(
      clientStore.productsWithPriceChanges.map((item) => item.itemID)
    )
    const productsWithPriceChangesInCart: CartItem[] =
      clientStore.ClientState.data?.Cart?.Content?.filter((item) =>
        productsWithPriceChangesIDs.has(item.ID)
      ) ?? []

    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.changesPriceProductsMessage'),
      products: productsWithPriceChangesInCart
    })
  }

  if (unavailableProducts.length > 0) {
    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.unavailableProductsMessage'),
      products: unavailableProducts
    })
  }

  const productsThatExceedCount = (clientStore.ClientState.data?.Cart?.Content ?? []).filter(
    (item) => item.IsExceedMaxCount && !item.Removed
  )

  if (productsThatExceedCount.length > 0) {
    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.changedMaxCountMessage'),
      products: productsThatExceedCount
    })
  }

  if (
    productsThatExceedCount.length > 0 ||
    unavailableProducts.length > 0 ||
    clientStore.productsWithPriceChanges.length > 0
  ) {
    await popupStore.showChangedProducts({
      content: popupChangedProductsContent,
      okFunction: async () => {
        await deleteItemsArray(unavailableProducts, true)
      }
    })

    return
  }

  const totalMessages = (clientStore.ClientState?.data?.MessagesV2 ?? []).filter(
    (m: MessagesV2) =>
      m.Type === MessageType.ImportantNotBlocking ||
      m.Type === MessageType.Important ||
      m.Type === MessageType.ImportantCustom ||
      m.Type === MessageType.ImportantNotBlockingCustom
  )

  if (totalMessages.length === 0) {
    await afterMessagesOrder()

    return
  }

  const messagesToShow = totalMessages
    .filter(
      (m: MessagesV2) => m.Type === MessageType.Important || m.Type === MessageType.ImportantCustom
    )
    .map((m: MessagesV2) => m.Text)

  if (messagesToShow && messagesToShow.length > 0) {
    await popupStore.showError(messagesToShow.join(' \n'), 'important-message')
  } else {
    const messagesNotBlockingToShow = totalMessages
      .filter(
        (m: MessagesV2) =>
          m.Type === MessageType.ImportantNotBlocking ||
          m.Type === MessageType.ImportantNotBlockingCustom
      )
      .map((m: MessagesV2) => m.Text)

    if (messagesNotBlockingToShow && messagesNotBlockingToShow.length > 0) {
      await popupStore.showConfirm({
        message: messagesNotBlockingToShow.join(' \n'),
        noText: translate('secondStepButton.notBlockingNo'),
        type: 'warning',
        yesFunction: () => {
          afterMessagesOrder()
        },
        yesText: translate('secondStepButton.notBlockingYes')
      })
    }
  }
}

async function afterMessagesOrder(): Promise<void> {
  orderButtonBlocked.value = true
  try {
    if (
      appConfig.RestaurantSettingsPreRun.OneStageCart ||
      pathname === appConfig.VueSettingsPreRun.Links.CartSecondStep
    ) {
      if (await checkClientStateComplete()) {
        try {
          const answer = await clientStore.sendOrderV3()

          await (payType.value === PayType.QR
            ? popupStore.openPopup({
                popupClosable: false,
                popupName: 'qrPayPopup',
                popupProperties: new Map<string, unknown>([
                  ['orderId', answer.OrderID],
                  ['totalSum', answer.TotalAmountToPay]
                ])
              })
            : navigateTo(answer.SiteProcessingPath, { external: true }))
        } catch (error) {
          switch (error?.code) {
            case OrderSendErrors.OrderDestinationIsNotValid:
              await popupStore.showError(
                translate('secondStepButton.errorOrderDestinationIsNotValid'),
                'empty-invalid-or-forbidden'
              )
              break
            case OrderSendErrors.ClientInBlacklist:
              await popupStore.showError(
                translate('secondStepButton.errorClientInBlacklist'),
                'blacklist'
              )
              break
            case OrderSendErrors.CaptchaV2Failed:
            case OrderSendErrors.CaptchaV3Failed:
            case OrderSendErrors.CaptchaWasNotVerified:
              await popupStore.showError(
                translate('accountManagement.captchaValidationError'),
                'captcha'
              )
              break
            case OrderSendErrors.CartIsEmpty:
              await popupStore.showError(
                translate('secondStepButton.errorEmpty'),
                'empty-invalid-or-forbidden'
              )
              break
            case OrderSendErrors.MinSumIsNotReached:
              await popupStore.showError(
                translate('secondStepButton.errorMinSumGeneral'),
                'min-sum-not-reached'
              )
              break
            case OrderSendErrors.CartIsInvalid:
              await popupStore.showError(
                translate('secondStepButton.errorInvalid'),
                'empty-invalid-or-forbidden'
              )
              break
            case OrderSendErrors.PromoCodeIsInvalid:
              await popupStore.showError(translate('secondStepButton.errorPromoCodeIsInvalid'))
              break
            case OrderSendErrors.Unknown:
              await popupStore.showError(
                translate('secondStepButton.errorUnknown'),
                'empty-invalid-or-forbidden'
              )
              break
            case OrderSendErrors.ThirdPartAPI:
              if (error.message) {
                const accountStore = useAccountStore()

                await popupStore.showConfirm({
                  message: error.message,
                  noFunction: () => {
                    reloadNuxtApp()
                  },
                  noText: translate('confirmPopup.logoutTimeoutNo'),
                  type: 'error',
                  yesFunction: () => {
                    accountStore.logout()
                    reloadNuxtApp()
                  },
                  yesText: translate('confirmPopup.logoutTimeoutYes')
                })
                break
              }
              break
            case OrderSendErrors.OrderType:
              await popupStore.showError(translate('secondStepButton.errorOrderType'))
              break
            case OrderSendErrors.PaymentTypeIsUndefined:
              await popupStore.showError(translate('secondStepButton.errorPay'))
              break
            default:
              if (error.message) {
                await popupStore.showError(error.message)
              }
              console.error('Failed to send order v3', error)
          }
        }
      }
    } else if (await checkClientStateFirstStage()) {
      await navigateTo(appConfig.VueSettingsPreRun.Links.CartSecondStep)
    }
  } catch (error) {
    console.error('store request error', error)
  } finally {
    orderButtonBlocked.value = false
  }
}

async function checkClientStateFirstStage(): Promise<boolean> {
  if (!clientStore.ClientState?.data) {
    console.error('Somehow there is no client state!')

    return false
  }
  if (!terminal.value) {
    console.error('Somehow there is no terminal!')

    return false
  }

  if (!validationStore.formPassedCheck(validatorForm)) {
    return false
  }

  if (clientStore.ClientState?.data.OrderType === OrderType.Default) {
    await popupStore.showError(
      translate('secondStepButton.errorOrderType'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  if (
    !clientStore.ClientState?.data.Cart ||
    !clientStore.ClientState?.data.Cart.Content ||
    clientStore.ClientState?.data.Cart.Content.length === 0
  ) {
    await popupStore.showError(translate('secondStepButton.errorEmpty'), 'empty-invalid-or-forbidden')

    return false
  }

  if (clientStore.ClientState?.data.Cart.Forbidden) {
    await popupStore.showError(
      translate('secondStepButton.errorForbidden'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  if (clientStore.ClientState?.data.Invalid) {
    await popupStore.showError(translate('secondStepButton.errorInvalid'), 'empty-invalid-or-forbidden')

    return false
  }

  switch (clientStore.ClientState?.data.OrderType) {
    case OrderType.CourierDelivery: {
      if (Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedDeliveryAddress?.CityID)) {
        await popupStore.showError(translate('secondStepButton.errorDeliveryCity'), 'wrong-address')

        return false
      }
      if (stringIsNullOrWhitespace(clientStore.ClientState?.data.SelectedDeliveryAddress?.DistrictID)) {
        await popupStore.showError(translate('secondStepButton.errorDeliveryDistrict'), 'wrong-address')

        return false
      }
      if (Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedDeliveryAddress?.DepartmentID)) {
        await popupStore.showError(
          translate('secondStepButton.errorDeliveryDepartment'),
          'wrong-address'
        )

        return false
      }
      if (Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedDeliveryAddress?.TerminalID)) {
        await popupStore.showError(translate('secondStepButton.errorDeliveryTerminal'), 'wrong-address')

        return false
      }

      break
    }
    case OrderType.NoShipment: {
      if (Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedSelfserviceTerminalID)) {
        await popupStore.showError(translate('secondStepButton.errorSelfService'), 'wrong-address')

        return false
      }

      break
    }
    case OrderType.InHall: {
      if (Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedInHall?.TerminalID)) {
        await popupStore.showError(translate('secondStepButton.errorInHall'), 'wrong-address')

        return false
      }
      if ((clientStore.ClientState?.data.SelectedInHall?.TableNumber ?? 0) === 0) {
        await popupStore.showError(translate('secondStepButton.errorInHall'), 'wrong-address')

        return false
      }

      break
    }
  }

  const terminalCheck = await addressStore.checkTerminal(terminal.value.ID)

  if (!terminalCheck.enableTerminal) {
    await popupStore.showError(
      terminalCheck.unavailableMessage.length > 0
        ? terminalCheck.unavailableMessage
        : translate('secondStepButton.errorDeliveryTerminal')
    )

    return false
  }

  const districtDeliveryParameters = districtDeliveryParametersByTerminal()

  if (districtDeliveryParameters.IsPriceRulesOn) {
    const amount = clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.Amount ?? 0
    if (districtDeliveryParameters.PriceRules.length === 0) {
      //misconfiguration, but still there should be a notification
      await popupStore.showError(translate('secondStepButton.errorInternalSettings'))

      return false
    } else if (amount < (clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0)) {
      await popupStore.showError(
        translate('secondStepButton.errorMinSum', {
          minsum: clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0
        }),
        'min-sum-not-reached'
      )

      return false
    }
  }

  if (terminalTemporarilyNoActive.value.IsTerminalNoActive) {
    if (terminalTemporarilyNoActive.value.IsTemporarilyNonActive) {
      await popupStore.showError(
        translate('errorNotifier.infoTerminalTemporarilyNonActive'),
        'unavailable-terminal'
      )

      return false
    } else if (terminalTemporarilyNoActive.value.TemporarilyUnavailableForDelivery) {
      await popupStore.showError(
        translate('errorNotifier.infoDeliveryTemporarilyUnavailable'),
        'unavailable-terminal'
      )

      return false
    } else if (terminalTemporarilyNoActive.value.TemporarilyUnavailableForSelfService) {
      await popupStore.showError(
        translate('errorNotifier.infoSelfServiceTemporarilyUnavailable'),
        'unavailable-terminal'
      )

      return false
    }
  }

  return true
}

async function checkClientStateComplete(): Promise<boolean> {
  if (!(await checkClientStateFirstStage())) {
    //popup with error was already called at this point
    //here we are only stop any further checks
    return false
  }
  if (!clientStore.ClientState?.data?.StateOrderData) {
    console.error('Somehow there is no StateOrderData!')

    return false
  }

  if (stringIsNullOrWhitespace(clientStore.ClientState?.data.StateOrderData.Name)) {
    await popupStore.showError(
      translate('secondStepButton.errorPersonalInfo'),
      'personal-info-not-filled'
    )

    return false
  }

  if (stringIsNullOrWhitespace(clientStore.ClientState?.data.StateOrderData.Phone)) {
    await popupStore.showError(
      translate('secondStepButton.errorPersonalInfo'),
      'personal-info-not-filled'
    )

    return false
  }

  if (clientStore.courierDelivery) {
    if (
      stringIsNullOrWhitespace(clientStore.ClientState?.data?.SelectedDeliveryAddress?.Street) &&
      stringIsNullOrWhitespace(clientStore.ClientState?.data?.SelectedDeliveryAddress?.GeoCity)
    ) {
      // Стоит немного скорректировать условие валидации
      await popupStore.showError(translate('secondStepButton.errorDeliveryStreet'), 'wrong-address')

      return false
    }

    if (stringIsNullOrWhitespace(clientStore.ClientState?.data.SelectedDeliveryAddress?.House)) {
      await popupStore.showError(translate('secondStepButton.errorDeliveryHouse'), 'wrong-address')

      return false
    }
  }

  if (payType.value === PayType.Undefined) {
    await popupStore.showError(translate('secondStepButton.errorPay'), 'no-pay-selected')

    return false
  }

  if (
    appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0 &&
    receiptSendType.value === ReceiptSendType.Default
  ) {
    await popupStore.showError(translate('secondStepButton.errorRecipient'), 'no-receipt-selected')

    return false
  }

  return true
}

watch(
  () => clientStore.ClientState.data?.AverageTimeSec ?? 0,
  async (newValue: number, oldValue: number) => {
    if (
      import.meta.client &&
      newValue !== 0 &&
      newValue !== oldValue &&
      appConfig.VueSettingsPreRun.AverageTimeDisplayWarning &&
      newValue >= appConfig.VueSettingsPreRun.AverageTimeDisplayWarningThreshold * 60
    ) {
      await popupStore.showWarning(
        translate(
          clientStore.courierDelivery
            ? 'secondStepButton.averageTimeDelivery'
            : 'secondStepButton.averageTime',
          {
            min: Math.round((newValue + (clientStore.ClientState.data?.DelayTimeSec ?? 0)) / 60)
          }
        )
      )
    }
  }
)
watch(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo.ModifiedMinSum ?? 0,
  async (newValue: number, oldValue: number) => {
    if (newValue !== oldValue && clientStore.ClientState.data?.DeliveryPaymentInfo.ChangedMinSumByRule) {
      await popupStore.showWarning(
        `${translate('secondStepButton.changedMinSubByRule', {
          price: clientStore.ClientState.data?.DeliveryPaymentInfo.ModifiedMinSum ?? 0
        })}${restaurantStore.CurrencySymbol}`
      )
    }
  },
  { deep: true }
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-cart-data-warning {
  margin-bottom: 10px;

  .v-link {
    text-decoration: underline;
  }
}

.v-label-check-privacy {
  margin: 0;
  padding: 0;

  .v-checkbox {
    flex: 0 0 20px;
    max-width: 20px;
  }

  .v-checkbox-text {
    flex: 0 0 90%;
    max-width: 90%;
    margin-left: 15px;
  }
}

.v-submit-button {
  width: 100%;

  .v-currency-wrapper {
    font-weight: 600;
  }
}

.v-min-delivery-sum {
  display: flex;
  align-items: flex-end;
  font-size: variables.$TextSizeMain;
  gap: 3px;
}

a {
  color: variables.$LinkColor;
}
</style>
